import styled from 'styled-components'
import { mediumBreakpoint } from 'config/css-constants'

export const PostContainer = styled.section`
  max-width: 692px;
  margin: auto;
  @media all and (max-width: ${mediumBreakpoint}) {
    padding: 20px 30px;
  }
`

export const Title = styled.h1`
  font-weight: 600;
`

export const Author = styled.span`
  font-size: 0.8rem;
`

export const Date = styled.span`
  font-size: 0.8rem;
`

export const BackToProjectWrapper = styled.div`
  text-align: center;
  margin-top: 30px;
`

export const Content = styled.div`
  li {
    margin: 0.7rem 1rem;
  }
  table {
    margin-top: 16px;
    --border: 1px solid #4a4a4a;
    border-radius: 10px;
    border-spacing: 0;
    border-collapse: separate;
    border: var(--border);
    overflow: hidden;
  }
  table th, table td {
    padding: 12px 15px;
  }
  table thead tr {
    background: #36304a;
    text-transform: uppercase;
  }
  table thead tr th strong {
    color: #fff;
  }
  table th:not(:last-child),
  table td:not(:last-child) {
    border-right: var(--border);
  }
  table>tbody>tr:not(:last-child)>td {
    border-bottom: var(--border);
  }
`
