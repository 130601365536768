import React from 'react'
import { graphql } from 'gatsby'
import BlogSinglePost from 'components/Blog/BlogSinglePost/BlogSinglePost'
import './blogPost.css'
import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader'
deckDeckGoHighlightElement()

const Template = ({ data }) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark

  return <BlogSinglePost data={frontmatter} htmlcode={html} />
}

export const query = graphql`
  query ($pathSlug: String!) {
    markdownRemark(frontmatter: { path: { eq: $pathSlug } }) {
      frontmatter {
        title
        excerpt
        imageName
        date
        author
      }
      html
    }
  }
`

export default Template
