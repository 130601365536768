import React from 'react'
import {
  Author,
  Date,
  PostContainer,
  Title,
  BackToProjectWrapper,
  Content,
} from './styles'
import { ButtonLink } from '../../UI/button'
import SEO from '../../Seo'

const BlogSinglePost = (props) => {
  const { data, htmlcode } = props

  return (
    <PostContainer className={'blog-post'}>
      <SEO title={data.title} description={data.excerpt} />
      <Title>{data.title}</Title>
      <Author>{data.author}</Author> | <Date>{data.date}</Date>
      <Content dangerouslySetInnerHTML={{ __html: htmlcode }} />
      <BackToProjectWrapper>
        <ButtonLink to={'/blog'}>Back to blog</ButtonLink>
      </BackToProjectWrapper>
    </PostContainer>
  )
}

export default BlogSinglePost
